import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { GridLayoutProps, OnDocumentClick } from '../Layout.types';
import { GridLayoutItem } from './GridLayoutItem';
import { DocumentClickOrigin } from '../../platform/searchResultsControllerStore';

import { st, classes } from './GridLayout.st.css';

export const GridLayout: React.FC<GridLayoutProps> = ({
  items,
  label,
  listRef,
  onItemLinkClick,
  renderItem,
  ...formatProps
}) => {
  const { isMobile, isEditorX, isCssPerBreakpoint } = useEnvironment();

  return (
    <div className={st(classes.container)}>
      <ul
        className={st(classes.root, {
          mobileView: isMobile,
          fluid: isEditorX || isCssPerBreakpoint,
        })}
        data-hook="grid-layout"
        ref={listRef}
        tabIndex={-1}
        role="region list"
        aria-label={label}
      >
        {items.map((item, index) => {
          const handleLinkClick: OnDocumentClick = (
            e,
            clickOrigin: DocumentClickOrigin,
          ) => onItemLinkClick?.(e, item, index, clickOrigin);
          const itemProps = {
            ...formatProps,
            item,
            onLinkClick: handleLinkClick,
          };
          return renderItem ? (
            renderItem(itemProps, item.id)
          ) : (
            <GridLayoutItem
              {...formatProps}
              item={item}
              key={item.id}
              onLinkClick={handleLinkClick}
            />
          );
        })}
      </ul>
    </div>
  );
};
