import { Experiments } from '@wix/yoshi-flow-editor';

import { Spec } from '@wix/site-search-common';

interface ResultsMessageKeySettings {
  isWithNumber: boolean;
  isWithQuery: boolean;
}

const getResultsFoundMessageKey = (
  { isWithNumber, isWithQuery }: ResultsMessageKeySettings,
  {
    withNumberAndQuery,
    withNumber,
    withQuery,
  }: {
    withNumberAndQuery: string;
    withNumber: string;
    withQuery: string;
  },
) => {
  if (isWithNumber && isWithQuery) {
    return withNumberAndQuery;
  }

  return isWithNumber ? withNumber : withQuery;
};

const getResultsEmptyMessageKey = (
  { isWithNumber, isWithQuery }: ResultsMessageKeySettings,
  {
    empty,
    withNumberAndQuery,
    withNumber,
    withQuery,
  }: {
    empty: string;
    withNumberAndQuery: string;
    withNumber: string;
    withQuery: string;
  },
) => {
  if (!isWithNumber && !isWithQuery) {
    return empty;
  }

  if (isWithNumber && isWithQuery) {
    return withNumberAndQuery;
  }

  return isWithNumber ? withNumber : withQuery;
};

export const getNoFacetsResultsFoundMessageKey = (
  settings: ResultsMessageKeySettings,
): string => {
  return getResultsFoundMessageKey(settings, {
    withNumberAndQuery: 'resultsFoundMessage.withNumberAndQuery',
    withNumber: 'resultsFoundMessage.withNumber',
    withQuery: 'resultsFoundMessage.withQuery',
  });
};

export const getFacetsResultsFoundMessageKey = (
  settings: ResultsMessageKeySettings,
  isMaxCount: boolean,
): string => {
  return getResultsFoundMessageKey(settings, {
    withNumberAndQuery: isMaxCount
      ? 'resultsFoundMessage.withNumberAndQuery'
      : 'facetsResultsFoundMessage.withNumberAndQuery',
    withNumber: isMaxCount
      ? 'resultsFoundMessage.withNumber'
      : 'facetsResultsFoundMessage.withNumber',
    withQuery: 'resultsFoundMessage.withQuery',
  });
};

export const getNoFacetsResultsEmptyMessageKey = (): string =>
  'resultsEmptyMessage.emptyTryNew';

export const getFacetsResultsEmptyMessageKey = (
  settings: ResultsMessageKeySettings,
): string => {
  return getResultsEmptyMessageKey(settings, {
    empty: 'resultsEmptyMessage.empty',
    withNumberAndQuery: 'facetsResultsEmptyMessage.withNumberAndQuery',
    withNumber: 'facetsResultsEmptyMessage.withNumber',
    withQuery: 'noResultsFoundMessage.withQuery',
  });
};
