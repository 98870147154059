import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import {
  Checkbox,
  CheckboxGroup,
  TextButtonPriority,
} from 'wix-ui-tpa/cssVars';

import { IWidgetProps } from '../../../../components/SearchResults/Widget/components/Widget';
import { ProductFacet } from '../ProductFacet';
import { st, classes } from './ProductFacetsCollections.st.css';
import { getVisibleCollections } from '../../../../lib/productFacets';
import { AvailableFacets, FacetFilterValue } from '../../..';
import { FacetOptionsMap } from '../../../../types/core/facets';
import { TextButton } from 'wix-ui-tpa';
import { Spec } from '@wix/site-search-common';

interface ProductFacetsCollectionsProps {
  collections: FacetOptionsMap[AvailableFacets['collections']['type']];
  selectedCollections?: FacetFilterValue['collections'];
  onFacetsFilterChange: IWidgetProps['onFacetsFilterChange'];
}

export const ProductFacetsCollections: FC<ProductFacetsCollectionsProps> = ({
  collections,
  selectedCollections = [],
  onFacetsFilterChange,
}) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { experiments } = useExperiments();

  const { visibleCollections, renderToggle } = useVisibleCollections({
    collections,
    selectedCollections,
  });

  const renderLabel = (title: string, count: number) => {
    return (
      <>
        {title} <span className={classes.count}>({count})</span>
      </>
    );
  };

  return (
    <ProductFacet title={t('searchResults.facets.collectionTitle')}>
      <CheckboxGroup className={st(classes.root, { mobileView: isMobile })}>
        {visibleCollections.map(({ label, count }) => (
          <Checkbox
            data-hook={`facet-collection-item-${label}`}
            className={classes.checkbox}
            label={renderLabel(label, count)}
            checked={selectedCollections.includes(label)}
            key={label}
            onChange={({ checked }) => {
              onFacetsFilterChange({
                collections: checked
                  ? [...selectedCollections, label]
                  : [...selectedCollections.filter((v) => v !== label)],
              });
            }}
          />
        ))}
      </CheckboxGroup>
      {experiments.enabled(Spec.ShowMoreCollections) && renderToggle()}
    </ProductFacet>
  );
};

const useVisibleCollections = ({
  collections,
  selectedCollections = [],
}: {
  collections: FacetOptionsMap[AvailableFacets['collections']['type']];
  selectedCollections?: FacetFilterValue['collections'];
}) => {
  const { t } = useTranslation();
  const visibleCollections = useMemo(
    () => getVisibleCollections(collections, selectedCollections),
    [collections, selectedCollections],
  );
  const hiddenCollectionsCount = collections.length - visibleCollections.length;

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleIsExpanded = useCallback(
    () => setIsExpanded(!isExpanded),
    [isExpanded],
  );
  const expand = useCallback(() => setIsExpanded(true), []);
  const collapse = useCallback(() => setIsExpanded(false), []);

  const renderToggle = useCallback(() => {
    if (!hiddenCollectionsCount) {
      return null;
    }
    if (isExpanded) {
      return (
        <TextButton onClick={collapse} priority={TextButtonPriority.link}>
          {t('searchResults.facets.collections.showLess')}
        </TextButton>
      );
    } else {
      return (
        <TextButton onClick={expand} priority={TextButtonPriority.link}>
          {t('searchResults.facets.collections.showMore', {
            hiddenCount: hiddenCollectionsCount,
          })}
        </TextButton>
      );
    }
  }, [collapse, expand, hiddenCollectionsCount, isExpanded, t]);

  return {
    visibleCollections: isExpanded ? collections : visibleCollections,
    isExpanded,
    toggleIsExpanded,
    expand,
    collapse,
    renderToggle,
  };
};
