import { WIX_BOOKINGS } from '@wix/app-definition-ids';
import { SearchDocumentType } from '@wix/client-search-sdk';
import { IndexableApp, LayoutType } from '../types';

export const BookingsApp: IndexableApp = {
  documentType: SearchDocumentType.Bookings,
  appDefId: WIX_BOOKINGS,
  translations: {
    tabLabel: 'searchResults.tabs.label.bookings',
    tabLabelWithCount: 'searchResults.tabs.label.bookings.withCount',
    tabAccessibilityLabel:
      'searchResults.tabs.label.bookings.accessibilityLabel',
  },
  layout: LayoutType.List,
};
