import * as React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { st, classes } from './ResultsStatus.st.css';

interface IResultsStatus {
  text: string;
}

export const ResultsStatus: React.FC<IResultsStatus> = ({ text }) => {
  const { isMobile } = useEnvironment();
  return (
    <p
      className={st(classes.root, { mobileView: isMobile })}
      data-hook="total-results"
    >
      {text}
    </p>
  );
};
