import { WIX_EVENTS } from '@wix/app-definition-ids';
import { SearchDocumentType } from '@wix/client-search-sdk';
import { IndexableApp, LayoutType } from '../types';

export const EventsApp: IndexableApp = {
  documentType: SearchDocumentType.Events,
  appDefId: WIX_EVENTS,
  translations: {
    tabLabel: 'searchResults.tabs.label.events',
    tabLabelWithCount: 'searchResults.tabs.label.events.withCount',
    tabAccessibilityLabel: 'searchResults.tabs.label.events.accessibilityLabel',
  },
  layout: LayoutType.List,
};
