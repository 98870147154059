import { SearchDocumentType } from '@wix/client-search-sdk';
import { IndexableApp, LayoutType } from '../types';

export const PagesApp: IndexableApp = {
  documentType: SearchDocumentType.Pages,
  translations: {
    tabLabel: 'searchResults.tabs.label.otherPages',
    tabLabelWithCount: 'searchResults.tabs.label.otherPages.withCount',
    tabAccessibilityLabel:
      'searchResults.tabs.label.otherPages.accessibilityLabel',
  },
  layout: LayoutType.List,
};
