import React from 'react';
import CloseIcon from 'wix-ui-icons-common/system/Close';

import { classes } from './ClearButton.st.css';

export interface IClearButtonProps {
  onClick(): void;
  label?: string;
}

export const ClearButton: React.FC<IClearButtonProps> = ({
  onClick,
  label,
}) => {
  return (
    <button
      type="button"
      className={classes.root}
      onClick={onClick}
      data-hook="clear-button"
      aria-label={label}
    >
      <CloseIcon width="8px" height="8px" />
    </button>
  );
};
