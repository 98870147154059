import React from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import {
  Pagination as TPAPagination,
  PaginationProps as TPAPaginationProps,
} from 'wix-ui-tpa/cssVars';
import ChevronLeft from 'wix-ui-icons-common/on-stage/ChevronLeft';
import ChevronRight from 'wix-ui-icons-common/on-stage/ChevronRight';
import settingsParams from '../../../settingsParams';

import { classes, st } from './Pagination.st.css';

interface PaginationProps {
  totalPages: number | null;
  currentPage: number | null;
  onPageChange: TPAPaginationProps['onChange'];
  buildPageUrl(pageNumber: number): string;
}

const MAX_PAGES_TO_SHOW_IN_PAGINATION = {
  DESKTOP: 5,
  MOBILE: 3,
};

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  buildPageUrl,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { t } = useTranslation();
  const { experiments } = useExperiments();

  const paginationAlignment = settings.get(settingsParams.paginationAlignment);

  const previousLabel = (
    <div className={classes.paginationLabel}>
      <ChevronLeft />
      <span>{t('searchResults.pagination.previous')}</span>
    </div>
  );
  const nextLabel = (
    <div className={classes.paginationLabel}>
      <span>{t('searchResults.pagination.next')}</span>
      <ChevronRight />
    </div>
  );

  const desktopProps: Partial<TPAPaginationProps> = {
    previousLabel,
    nextLabel,
    showFirstLastNavButtons: false,
  };

  return currentPage && totalPages ? (
    <div
      className={st(classes.root, {
        mobileView: isMobile,
        alignment: paginationAlignment,
      })}
      data-hook="pagination-root"
    >
      <TPAPagination
        totalPages={totalPages}
        currentPage={currentPage}
        maxPagesToShow={isMobile ? MAX_PAGES_TO_SHOW_IN_PAGINATION.MOBILE : 7}
        showFirstPage
        showLastPage
        pageUrl={buildPageUrl}
        onChange={onPageChange}
        data-hook="pagination"
        className={classes.pagination}
        {...(!isMobile && desktopProps)}
      />
    </div>
  ) : null;
};
